import React from "react";
import AuthSidebarBackground from "../layouts/AuthSidebarBackground";
import AuthContentFooter from "../layouts/AuthContentFooter";

export function AuthTheme({children}) {
    return (
        <div className="d-flex flex-column flex-root">
            <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                <AuthSidebarBackground />

                <div className="d-flex flex-column flex-lg-row-fluid py-10">
                    {children}
            
                    <AuthContentFooter />
                </div>
            </div>
        </div>
    );
}