import axios from "axios";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { notifyToTopRight } from '../../../services/notifs/Toastify';

const initialForm = {
    country_id: '',
    phone: '',
    password: '',
    password_confirmation: '',
    last_name: '',
    first_name: '',
    email: '',
    city: '',
    address: '',
    piece_1_name: '',
    piece_1_num: '',
    terms: false,
}

export function RegisterForm(props) {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const {
        currentCountry,
        pieces,
        termModalToggle,
        useFormParams
    } = props

    const {
        register,
        handleSubmit,
        control,
        watch,
        reset,
        formState: {errors}
    } = useFormParams

    const registerOptions = {
        country_id: currentCountry.id,
        phone: {required: 'Ce champ est requis !'},
        password: {required: 'Ce champ est requis !'},
        password_confirmation: {required: 'Ce champ est requis !'},
        last_name: {required: 'Ce champ est requis !'},
        first_name: {required: 'Ce champ est requis !'},
        email: {required: 'Ce champ est requis !'},
        city: {required: 'Ce champ est requis !'},
        address: {required: 'Ce champ est requis !'},
        piece_1_name: {required: 'Ce champ est requis !'},
        piece_1_num: {required: 'Ce champ est requis !'},
        terms: {required: 'Veuillez accepter les conditions d\'utilisations !'},
    }

    const validatePhoneNumber = (value) => {
        // const phoneNumberPattern = /^[0-9]{7,10}$/;
        const phoneNumberPattern = /^[0-9]{8}$/;
        if (!phoneNumberPattern.test(value)) {
            return "Le numéro de téléphone doit contenir 8 chiffres sans espaces ni caractères spéciaux !";
        }
        return true;
    }

    const validatePasswordConfirmation = (value) => {
        const { password } = watch();
        if (value !== password) {
            return "Les mots de passe ne correspondent pas !";
        }
        return true;
    }

    const validateEmail = (value) => {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailPattern.test(value)) {
            return "Adresse e-mail invalide !";
        }
        return true;
    };

    const sendRegisterData = async (params) => {
        setLoading(true)
        // console.log(params)

        const response = await axios.post(process.env.REACT_APP_API_URL+'/adhering/add/basic', params, {
            headers: {
                'Content-Type': 'application/json',
                // 'enctype': 'multipart/form-data',
                'key': '03dc8174a44d9d2845a2fbc707e80019',
            }
        })

        if(response.data) {
            if(response.data?.type === 'success') {
                notifyToTopRight('success', response.data?.message)
                setTimeout(() => {}, 2000)
                await reset(initialForm)
                navigate('/adhering/register-enjoy')
            } else {
                notifyToTopRight('error', response.data?.message)
            }
        } else {
            notifyToTopRight('error', "Quelque chose s'est mal passée !")
        }

        setLoading(false)
    }

    return <>
        <form className="form w-100" onSubmit={handleSubmit(sendRegisterData)}>
            <div className="mb-10 text-center">
                <h1 className="text-dark mb-3">
                    COMPTE BASIC
                </h1>
                <div className="fw-bold fs-3" style={{color: "#4c2a86"}}> {/* text-gray-400  | style={{color: "#4c2a86"}} */}
                    Compte plafonné à 200 000 FCFA. Le compte Basic est utile pour les services et achats du quotidien : frais de transport (Taxi, ZED ou Parking), achats et paiements de factures, ou encore pour le transfert d'argent.
                </div>
            </div>

            <div className="d-flex align-items-center mb-10">
                <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                {/* <span className="fw-bold text-gray-400 fs-7 mx-2"></span> */}
                <div className="border-bottom border-gray-300 mw-50 w-100"></div>
            </div>

            <div className="row fv-row">
                <div className="col-xl-6 mb-7">
                    <label className="form-label fw-bolder text-dark fs-6">
                        Indicatif
                    </label>
                    <input className="form-control form-control-lg form-control-solid" type="text" placeholder="" 
                        defaultValue={`${currentCountry.name} (${currentCountry.phoneCodePlus})`}
                        readOnly={true}
                    />
                    <input type="text" style={{display: "none"}}
                        name="country_id"
                        defaultValue={currentCountry.id}
                        {...register('country_id', registerOptions.country_id)}
                    />
                </div>

                <div className="col-xl-6 mb-7">
                    <label className="form-label fw-bolder text-dark fs-6">
                        N° de téléphone
                    </label>
                    <Controller
                        name="phone"
                        control={control}
                        rules={{ 
                            required: "Ce champ est requis !",
                            validate: validatePhoneNumber 
                        }}
                        render={({ field }) => (
                            <>
                                <input className="form-control form-control-lg form-control-solid" type="text" placeholder="" 
                                    {...field}
                                />
                                {errors.phone && <small className="text-danger">{errors.phone.message}</small>}
                            </>
                        )}
                    />
                </div>
            </div>

            <div className="row fv-row">
                <div className="col-xl-6 mb-7">
                    <label className="form-label fw-bolder text-dark fs-6">
                        Mot de passe
                    </label>
                    <input className="form-control form-control-lg form-control-solid" type="password" placeholder="" 
                        name="password"
                        {...register('password', registerOptions.password)}
                    />
                    {errors.password && <small className="text-danger">{errors.password.message}</small>}
                </div>

                <div className="col-xl-6 mb-7">
                    <label className="form-label fw-bolder text-dark fs-6">
                        Confirmer mot de passe
                    </label>
                    <Controller
                        name="password_confirmation"
                        control={control}
                        rules={{
                            required: "Ce champ est requis !",
                            validate: validatePasswordConfirmation
                        }}
                        render={({ field }) => (
                            <>
                                <input className="form-control form-control-lg form-control-solid" type="password" placeholder=""
                                    {...field}
                                />
                                {errors.password_confirmation && <small className="text-danger">{errors.password_confirmation.message}</small>}
                            </>
                        )}
                    />
                </div>
            </div>

            <div className="row fv-row">
                <div className="col-xl-6 mb-7">
                    <label className="form-label fw-bolder text-dark fs-6">
                        Nom de famille
                    </label>
                    <input className="form-control form-control-lg form-control-solid" type="text" placeholder="" 
                        name="last_name"
                        {...register('last_name', registerOptions.last_name)}
                    />
                    {errors.last_name && <small className="text-danger">{errors.last_name.message}</small>}
                </div>

                <div className="col-xl-6 mb-7">
                    <label className="form-label fw-bolder text-dark fs-6">
                        Prénom(s)
                    </label>
                    <input className="form-control form-control-lg form-control-solid" type="text" placeholder="" 
                        name="first_name"
                        {...register('first_name', registerOptions.first_name)}
                    />
                    {errors.first_name && <small className="text-danger">{errors.first_name.message}</small>}
                </div>
            </div>

            <div className="fv-row mb-7">
                <label className="form-label fw-bolder text-dark fs-6">
                    Adresse email
                </label>
                <Controller
                    name="email"
                    control={control}
                    rules={{
                        required: "Ce champ est requis !",
                        validate: validateEmail
                    }}
                    render={({ field }) => (
                        <>
                            <input className="form-control form-control-lg form-control-solid" type="email" placeholder="" 
                                {...field}
                            />
                            {errors.email && <small className="text-danger">{errors.email?.message}</small>}
                        </>
                    )}
                />
            </div>

            <div className="fv-row mb-7">
                <label className="form-label fw-bolder text-dark fs-6">
                    Ville
                </label>
                <input className="form-control form-control-lg form-control-solid" type="text" placeholder="" 
                    name="city"
                    {...register('city', registerOptions.city)}
                />
                {errors.city && <small className="text-danger">{errors.city.message}</small>}
            </div>

            <div className="fv-row mb-7">
                <label className="form-label fw-bolder text-dark fs-6">
                    Adresse de résidence
                </label>
                <input className="form-control form-control-lg form-control-solid" type="text" placeholder="" 
                    name="address"
                    {...register('address', registerOptions.address)}
                />
                {errors.address && <small className="text-danger">{errors.address.message}</small>}
            </div>

            <div className="row fv-row">
                <div className="col-xl-6 mb-7">
                    <label className="form-label fw-bolder text-dark fs-6">
                        Pièce d'identité
                    </label>
                    <select className="form-control form-control-lg form-control-solid"
                        name="piece_1_name"
                        {...register('piece_1_name', registerOptions.piece_1_name)}
                    >
                        <option value="">--- Sélectionnez la pièce ---</option>
                        {
                            pieces.map((pieceItem, pieceIndex) => {
                                return <option value={pieceItem.key} key={'piece_'+pieceIndex}>
                                    {pieceItem.label}
                                </option>
                            })
                        }
                    </select>
                    {errors.piece_1_name && <small className="text-danger">{errors.piece_1_name.message}</small>}
                </div>

                <div className="col-xl-6 mb-7">
                    <label className="form-label fw-bolder text-dark fs-6">
                        N° Pièce d'identité
                    </label>
                    <input className="form-control form-control-lg form-control-solid" type="text" placeholder="" 
                        name="piece_1_num" 
                        {...register('piece_1_num', registerOptions.piece_1_num)}
                    />
                    {errors.piece_1_num && <small className="text-danger">{errors.piece_1_num.message}</small>}
                </div>
            </div>

            <div className="fv-row mb-7">
                <label className="form-label fw-bolder text-dark fs-6">
                    Code de parrainage (facultatif)
                </label>
                <input className="form-control form-control-lg form-control-solid" type="text" placeholder="" 
                    name="check_referal_code"
                    {...register('check_referal_code', registerOptions.check_referal_code)}
                />
                {errors.check_referal_code && <small className="text-danger">{errors.check_referal_code.message}</small>}
            </div>

            <div className="fv-row mb-10">
                <label className="form-check form-check-custom form-check-solid form-check-inline">
                    <input className="form-check-input" type="checkbox"
                        name="terms"
                        {...register('terms', registerOptions.terms)}
                    />
                    <span className="form-check-label fw-bold text-gray-700 fs-6">
                        J'accepte 
                        <Link className="ms-1 link-primary" onClick={termModalToggle}>
                            les conditions d'utilisations
                        </Link>
                    </span>
                </label>
                {errors.terms && <small className="text-danger">{errors.terms?.message}</small>}
            </div>

            <div className="text-center">
                <button type="submit" className="btn btn-sm w-100" disabled={loading} style={{background: '#fbb53c', color: '#FFF'}}>
                    { 
                        loading
                        ?
                        <span className="indicator-label text-uppercase fs-5">
                            <span className="spinner-border spinner-border-sm align-middle me-2"></span>
                            Veuillez pattienter...
                        </span> 
                        :
                        <span className="indicator-label text-uppercase fw-bolder fs-4">
                            Enregistrer
                        </span> 
                    }
                </button>
            </div>
        </form>
    </>
}