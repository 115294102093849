import React from 'react';
import { Link } from 'react-router-dom';

// import epagarLogo from '../../assets/images/logo/epagar_h.png';
// import authBgImage from '../../assets/images/auth/auth-bg-002.png';
import epagarLogo2 from '../../assets/images/logo/epagar_h2.png';
import authBgImage2 from '../../assets/images/auth/checkout.png';

function AuthSidebarBackground() {
    return (
        <>
            {/* F2C98A | fbb53c | 4c2a86 | style={{backgroundColor: '#4c2a86'}} | style={{backgroundImage: `url(${authBgImage})`}} */}
            <div className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative bgi-no-repeat" style={{backgroundColor: '#F2C98A'}}>
                <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y">
                    <div className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20">
                        <Link to="/" className="py-9">
                            <img src={epagarLogo2} className="h-70px" alt="Logo" />
                        </Link>
                        <h1 className="fw-bolder fs-2qx pb-5 pb-md-10" style={{color: "#000"}}>
                            Inscription
                        </h1>
                        <p className="fw-bold fs-2" style={{color: "#000"}}>
                            Lancement ce 01 Août 2023
                            <br />
                            Prenez une longueur d'avance en vous inscrivant dès maintenant sur ePaGar, l'avenir du paiement mobile au Togo.
                        </p>
                    </div>

                    <div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px" 
                        style={{backgroundImage: `url(${authBgImage2})`}}
                    ></div>
                </div>
            </div>
        </>
    )
}

export default AuthSidebarBackground