import React from 'react'
import { AuthTheme } from '../AuthTheme'
import registerEnjoyImg from '../../../assets/images/auth/enjoy.png';

function RegisterEnjoy() {
    return (
        <AuthTheme>
            <div className="d-flex flex-center flex-column flex-column-fluid">
                <div className="w-lg-600px p-10 p-lg-15 mx-auto">
                    <div className="mb-10 text-center">
                        <h1 className="mb-3" style={{color: "#fbb53c"}}>
                            Félicitation ! Vous venez d'intégrer la famille des adhérents ePaGar !
                        </h1>
                        <div className="fw-bold fs-3" style={{color: "#4c2a86"}}>
                            Veuillez consulter votre courrier électronique pour avoir votre code PIN, nécessaire pour sécuriser vos transactions.
                        </div>
                    </div>

                    <br/><br/>
                    <br/><br/>

                    <img src={registerEnjoyImg} className='w-100' alt='' />
                </div>
            </div>

            <br/><br/>
            <br/><br/>
        </AuthTheme>
    )
}

export default RegisterEnjoy