export const DataSocieties = {
    maroc: {
        type: "SARL",
        name: "HONAME TECH",
        sigle: "HT",
        slogan: "",
        capital: "30 000 MAD",
        rc: "N° 441447",
        phone: "(+212) 6 05 269 874",
        email: "contact@honametech.com",
        domain: "www.honametech.com",
        website: "https://honametech.com/",
        location: "Residence AL Boustane 2, Imm 1 Mag 1 Bernoussi CASABLANCA - MAROC",
        favicon: "honame-tech/favicon.png",
        logo: "honame-tech/logo.png",
        currency: {
            name: "Dirham Marocain",
            code: "MAD",
        },
        dataProtection: {
            agencySigle: "CNDP",
            agencyName: "la Commission Nationale de contrôle de la protection des Données à caractère Personnel (CNDP)",
            lawText: {
                fr: "Les Parties s’engagent à respecter l’ensemble des dispositions légales et règlementaires applicables à leur activité, notamment celles issues de la loi n° 78-17 « relative à l’informatique, aux fichiers et aux libertés » (ci-après « Loi Informatique et Libertés »). A ce titre, le Client s'engage à satisfaire à l’ensemble des obligations déclaratives et/ou demandes d’autorisation auprès de la Commission Nationale de l’Informatique et des Libertés (CNIL), concernant les traitements de données à caractère personnel qu’il constitue et/ou exploite ou fait exploiter conséquemment à l’utilisation du Logiciel, ainsi qu’à respecter toutes les obligations incombant aux responsables de traitement au sens de la Loi Informatique et Libertés. Le Client fait son affaire de toute difficulté qui pourrait survenir du fait du non respect de ces obligations et s’engage à se conformer et à réaliser, tout au long du Contrat, les formalités et/ou démarches nécessaires au respect de la réglementation en vigueur, notamment celles qui seraient rendues nécessaires par l’évolution technique des Prestations. Les Parties rappellent qu’en application des dispositions de l’article 35 de la Loi Informatique et Libertés, les données à caractère personnel ne peuvent faire l'objet d'une opération de traitement de la part d'un sous-traitant, que si celui-ci agit sous l'autorité du responsable du traitement, via une « instruction ». Les Parties conviennent de définir la notion d'« instruction », au sens de l’article 35 précité, comme étant acquise lorsque Honamé Tech agit dans le cadre de l'exécution du Contrat.",
                en: "The Parties undertake to comply with all the legal and regulatory provisions applicable to their activity, in particular those resulting from law n ° 78-17 « relating to data processing, files and freedoms » (hereinafter « Law Computing and Freedom »). As such, the Customer undertakes to meet all the reporting obligations and / or authorization requests from the National Commission for Informatics and Freedoms (CNIL), concerning the processing of personal data. '' it constitutes and / or operates or causes to be exploited as a result of the use of the Software, as well as to comply with all the obligations incumbent on data controllers within the meaning of the Data Protection Act. The Customer is responsible for any difficulty that may arise due to non-compliance with these obligations and undertakes to comply and carry out, throughout the Contract, the formalities and / or procedures necessary to comply with the regulations in force. , in particular those which would be made necessary by the technical evolution of the Services. The Parties recall that in application of the provisions of Article 35 of the Data Protection Act, personal data may only be processed by a subcontractor if this acts under the authority of the controller, via an « instruction ». The Parties agree to define the notion of « instruction », within the meaning of Article 35 above, as being acquired when Honamé Tech acts within the framework of the execution of the Contract.",
            },
            applicableLawText1: {
                fr: "Le droit applicable à la présente lettre est le droit Marocain. Sauf dans les cas d’urgence justifiant le recours au juge des référés, les Parties s’engagent, en cas de différend survenant dans le cadre de l’exécution du présent accord, à mettre en œuvre une procédure destinée à faciliter un règlement amiable le plus rapidement possible, avant de saisir le juge compétent. Si dans un délai de quinze (15) jours suivant la date de cette réunion aucune solution n’est trouvée, entérinée par un écrit signé des deux Parties, chaque Partie reprendra sa liberté d’action.",
                en: "The law applicable to this letter is Moroccan law. Except in cases of urgency justifying recourse to the summary judge, the Parties undertake, in the event of a dispute arising in the context of the execution of this agreement, to implement a procedure intended to facilitate an amicable settlement on as quickly as possible, before referring the matter to the competent judge. If within fifteen (15) days of the date of this meeting no solution is found, endorsed by a writing signed by both Parties, each Party will resume its freedom of action."
            },
            applicableLawText2: {
                fr: "TOUTES DIFFICULTES RELATIVES A L'APPLICATION DE LA PRESENTE LETTRE SERONT SOUMISES, A DEFAUT D'ACCORD AMIABLE SELON LA PROCEDURE AMIABLE CI-DESSUS, AU TRIBUNAL DE COMMERCE DE CASABLANCA A QUI EST DONNEE COMPETENCE TERRITORIALE ET CECI MEME EN CAS DE REFERE, D’APPEL EN GARANTIE OU DE PLURALITE DE DEFENDEURS.",
                en: "ANY DIFFICULTIES RELATING TO THE APPLICATION OF THIS LETTER WILL BE SUBMITTED, Failing a friendly agreement under the above friendly procedure, to the commercial tribunal of CASABLANCA TO WHICH TERRITORIAL JURISDICTION IS GIVEN AND THIS IS GIVEN EVEN IN CASE OF REFERENCE. CALL IN WARRANTY OR PLURALITY OF DEFENDANTS."
            }
        }
    },
    togo: {
        type: "SARL",
        name: "KEKELI SOLUTIONS",
        sigle: "KS",
        slogan: "",
        capital: "...",
        rc: "...",
        phone: "+228 92 05 07 35 / 22 50 61 44",
        email: "",
        domain: "",
        website: "",
        location: "AVEDJI TELESSOU, MAISON APEDO, 2E VON A GAUCHE AVANT LA PHCIE APPOLON, Tél. +228 92 05 07 35 / 22 50 61 44 LOME – TOGO.",
        favicon: "kekeli-solutions/favicon.png",
        logo: "kekeli-solutions/logo.png",
        currency: {
            name: "Franc CFA",
            code: "XOF",
        },
        dataProtection: {
            agencySigle: "IPDCP",
            agencyName: "l’Instance de protection des données à caractère personnel (IPDCP) du Togo",
            lawText: {
                fr: "Les Parties s’engagent à respecter l’ensemble des dispositions légales et règlementaires applicables à leur activité, notamment celles issues de la loi n° 78-17 « relative à l’informatique, aux fichiers et aux libertés » (ci-après « Loi Informatique et Libertés »). A ce titre, le Client s'engage à satisfaire à l’ensemble des obligations déclaratives et/ou demandes d’autorisation auprès de l’Instance de protection des données à caractère personnel (IPDCP) du Togo, concernant les traitements de données à caractère personnel qu’il constitue et/ou exploite ou fait exploiter conséquemment à l’utilisation du Logiciel, ainsi qu’à respecter toutes les obligations incombant aux responsables de traitement au sens de la Loi Informatique et Libertés. Le Client fait son affaire de toute difficulté qui pourrait survenir du fait du non respect de ces obligations et s’engage à se conformer et à réaliser, tout au long du Contrat, les formalités et/ou démarches nécessaires au respect de la réglementation en vigueur, notamment celles qui seraient rendues nécessaires par l’évolution technique des Prestations. Les Parties rappellent qu’en application des dispositions de l’article 35 de la Loi Informatique et Libertés, les données à caractère personnel ne peuvent faire l'objet d'une opération de traitement de la part d'un sous-traitant, que si celui-ci agit sous l'autorité du responsable du traitement, via une « instruction ». Les Parties conviennent de définir la notion d'« instruction », au sens de l’article 35 précité, comme étant acquise lorsque KEKELI SOLUTIONS agit dans le cadre de l'exécution du Contrat.",
                en: "The Parties undertake to comply with all the legal and regulatory provisions applicable to their activity, in particular those resulting from law n ° 78-17 « relating to data processing, files and freedoms » (hereinafter « Law Computing and Freedom »). As such, the Customer undertakes to meet all the reporting obligations and / or authorization requests from Togo's personal data protection authority (IPDCP), concerning the processing of personal data. '' it constitutes and / or operates or causes to be exploited as a result of the use of the Software, as well as to comply with all the obligations incumbent on data controllers within the meaning of the Data Protection Act. The Customer is responsible for any difficulty that may arise due to non-compliance with these obligations and undertakes to comply and carry out, throughout the Contract, the formalities and / or procedures necessary to comply with the regulations in force. , in particular those which would be made necessary by the technical evolution of the Services. The Parties recall that in application of the provisions of Article 35 of the Data Protection Act, personal data may only be processed by a subcontractor if this acts under the authority of the controller, via an « instruction ». The Parties agree to define the notion of « instruction », within the meaning of Article 35 above, as being acquired when KEKELI SOLUTIONS acts within the framework of the execution of the Contract.",
            },
            applicableLawText1: {
                fr: "Le droit applicable à la présente lettre est le droit Togolais. Sauf dans les cas d’urgence justifiant le recours au juge des référés, les Parties s’engagent, en cas de différend survenant dans le cadre de l’exécution du présent accord, à mettre en œuvre une procédure destinée à faciliter un règlement amiable le plus rapidement possible, avant de saisir le juge compétent. Si dans un délai de quinze (15) jours suivant la date de cette réunion aucune solution n’est trouvée, entérinée par un écrit signé des deux Parties, chaque Partie reprendra sa liberté d’action.",
                en: "The law applicable to this letter is Togolese law. Except in cases of urgency justifying recourse to a summary judgment judge, the Parties undertake, in the event of a dispute arising in connection with the execution of this agreement, to implement a procedure designed to facilitate an amicable settlement as quickly as possible, before referring the matter to the competent judge. If within fifteen (15) days following the date of this meeting no solution is found, ratified by a written document signed by both Parties, each Party will resume its freedom of action."
            },
            applicableLawText2: {
                fr: "TOUTES DIFFICULTES RELATIVES A L'APPLICATION DE LA PRESENTE LETTRE SERONT SOUMISES, A DEFAUT D'ACCORD AMIABLE SELON LA PROCEDURE AMIABLE CI-DESSUS, AU TRIBUNAL DE COMMERCE DE LOME A QUI EST DONNEE COMPETENCE TERRITORIALE ET CECI MEME EN CAS DE REFERE, D’APPEL EN GARANTIE OU DE PLURALITE DE DEFENDEURS.",
                en: "ALL DIFFICULTIES RELATING TO THE APPLICATION OF THE PRESENT LETTER WILL BE SUBMITTED, IN THE ABSENCE OF AMICABLE AGREEMENT ACCORDING TO THE AMICABLE PROCEDURE ABOVE, TO THE COMMERCIAL COURT OF LOME TO WHICH IS GIVEN TERRITORIAL COMPETENCE AND THIS EVEN IN CASE OF REFEREE, APPEAL IN GUARANTEE OR PLURALITY OF DEFENDANTS."
            }
        }
    }
}