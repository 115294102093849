import { Route, Routes } from "react-router-dom";
import App from "../App";
// import { auth } from "../services/Auth";
import Register from "../pages/auth/register/Register";
import RegisterEnjoy from "../pages/auth/register/RegisterEnjoy";

const Routers = () => {
    // const {token} = auth || {}
    return (
        <>
            <Routes>
                <Route element={<App />}>
                    <Route path="/" element={<Register />} />
                    <Route path="/adhering/register" element={<Register />} />
                    <Route path="/adhering/register-enjoy" element={<RegisterEnjoy />} />
                </Route>
            </Routes>
        </>
    )
}

export default Routers;