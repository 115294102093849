import React from 'react'
import { Link } from 'react-router-dom'

function AuthContentFooter() {
  return (
    <>
      <div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
        <div className="d-flex flex-center fw-bold fs-6">
          <Link to="https://epagarmoney.com/agences" className="text-muted text-hover-primary px-2" target="_blank" rel='noopener'>
            Agences
          </Link>
          
          <Link to="https://epagarmoney.com/faq" className="text-muted text-hover-primary px-2" target="_blank" rel='noopener'>
            Assistances
          </Link>
        </div>
      </div>
    </>
  )
}

export default AuthContentFooter