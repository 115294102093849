import React, { useEffect, useState } from 'react'
import { AuthTheme } from '../AuthTheme'
import { RegisterForm } from './RegisterForm'
import axios from 'axios'
import { TermsModal } from '../TermsModal'
import { DataSocieties } from "../../../common/DataSocieties";
import { 
  accountCeiling,
  application,
  currentCountry
} from "../../../common/GlobalConstants";
import { useForm } from 'react-hook-form'

function Register() {
  const [termModal, setTermModal] = useState(false)
  const [countries, setCountries] = useState([])
  const [pieces, setPieces] = useState([])

  const termModalToggle = () => setTermModal(!termModal);
  const society = DataSocieties[currentCountry.key];

  const useFormParams = useForm({ mode: "onBlur" })

  const getCountries = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL+'/countries');
      if(response.data && response.data.type === 'success') {
        setCountries(response.data.result);
      } else {
        setCountries([]);
      }
    } catch (err) {
      console.error(err)
    }
  }

  const getPieces = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL+'/pieces/name/1');
      if(response.data && response.data.type === 'success') {
        setPieces(response.data.result);
      } else {
        setPieces([]);
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getCountries()
    getPieces()
  }, [])

  return (
    <>
      <AuthTheme>
        <div className="d-flex flex-center flex-column flex-column-fluid">
          <div className="w-lg-600px p-10 p-lg-15 mx-auto">
            <RegisterForm
              currentCountry={currentCountry}
              countries={countries}
              pieces={pieces}
              termModalToggle={termModalToggle}
              useFormParams={useFormParams}
            />
          </div>
        </div>

        <TermsModal 
          termModal={termModal}
          termModalToggle={termModalToggle}
          currentCountry={currentCountry}
          accountCeiling={accountCeiling}
          application={application}
          society={society}
        />
      </AuthTheme>
    </>
  )
}

export default Register