export const currentCountry = {
    id: 33,
    key: "togo",
    name: "Togo",
    nationality: "Togolaise",
    phoneCode: "228",
    phoneCodePlus: "+228"
}

export const application = {
    name: "ePaGar",
    sigle: "ePaGar",
    phone: "",
    email: "",
    domain: "www.epagarmoney.com",
    website: "https://epagarmoney.com/",
    favicon: "",
    logo: "",
    agenciesUrl: "https://epagarmoney.com/agences",
    demonstrationUrl: "",
    supportUrl: "https://epagarmoney.com/faq"
}

export const accountCeiling = {
    maroc: {
        registrationPrice: "500 MAD",
        basic: "200 MAD",
        premium: "5 000 MAD",
        gold: "20 000 MAD"
    },
    togo: {
        registrationPrice: "30 000 XOF",
        basic: "200 000 XOF",
        premium: "2 000 000 XOF",
        gold: "50 000 000 XOF"
    }
}