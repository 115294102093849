import React from "react";
import {
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";

export function TermsModal(props) {
    const {
        termModal,
        termModalToggle,
        accountCeiling,
        application, 
        society
    } = props;
    
    return (
        <>
            <Modal isOpen={termModal} toggle={termModalToggle} size="xl">
                <ModalHeader toggle={termModalToggle}>
                    CONDITIONS GÉNÉRALES DE VENTES ET D’UTILISATION
                </ModalHeader>

                <ModalBody>
                    <h4 className="card-title text-oth-black text-900">ENTRE LES SOUSSIGNES</h4>
                    <p className="card-title">
                        { society.name } { society.type }, au capital de { society.capital }, immatriculée au Registre du<br/>
                        Commerce de Casablanca sous le { society.rc }<br/>
                        Ci-après dénommée « { society.name } »,<br/>
                        D'une part,
                    </p>

                    <h4 className="card-title text-oth-black text-900">ET</h4>
                    <p className="card-title">
                        Vous-même, votre société<br/>
                        Ci-après dénommée «le Client»,<br/>
                        D'autre part.<br/>
                        Désignées ensemble les « Parties » et individuellement la « Partie ».
                    </p>

                    <h4 className="card-title text-oth-black text-900">PREAMBULE</h4>
                    <p className="card-title">{ society.name }, société de service en ingénierie informatique, fournit le logiciel suivante : application de transfert et de paiement électronique « { application.name } » via l’adresse <a href={ application.website } target="_blank" rel="noopener noreferrer">{ application.domain }</a> (ci-après dénommé le « Logiciel »). Le Logiciel est uniquement disponible en mode service. { society.name } reste joignable pour toute demande ou réclamation relative au fonctionnement du Logiciel grâce au formulaire de contact figurant sur le site du Logiciel ou, au choix du Client, à l’adresse suivante : <span>{ society.email }</span>. Le Client, afin d’accéder au Logiciel, reconnaît avoir lu et accepté les présentes conditions.</p>
                    
                    <h4 className="card-title text-oth-black text-900">ARTICLE 1 - OBJET</h4>
                    <p className="card-title">{ society.name } met le Logiciel à disposition du Client, via le site internet <a href={ application.website } target="_blank" rel="noopener noreferrer">{ application.domain }</a> (ci-après le « Site internet »). Le Logiciel est mis à disposition à titre payant selon le choix d’abonnement du Client. Les spécifications du Logiciel sont décrites sur le Site internet, lesquelles ont été lues et acceptées par le Client.</p>
                    
                    <h4 className="card-title text-oth-black text-900">ARTICLE 2 - DURÉE</h4>
                    <p className="card-title">Le présent contrat entre en vigueur à sa date d’acceptation par le Client et est conclu sans limitation de durée. Il pourra y être mis un terme par l’une ou l’autre des Parties selon les modalités de l’article « Résiliation »</p>

                    <h4 className="card-title text-oth-black text-900">ARTICLE 3 - OBLIGATIONS DES PARTIES</h4>
                    <h5 className="card-title text-oth-black text-500">3.1 – Obligations de { society.name }</h5>
                    <p className="card-title">{ society.name } est tenu auprès du Client, au titre d’une obligation de moyen, de maintenir l’accès du Logiciel via le Site internet à partir de tout terminal disposant des spécifications techniques requises afin de faire fonctionner le Logiciel. Lesdites spécifications techniques sont portées à la connaissance du Client sur le Site internet. { society.name } s’engage par ailleurs, au titre d’une obligation de moyen, à assurer la confidentialité des données que le Client transmettra au Logiciel via le Site internet. { society.name } est tenu, au même titre, de conserver l’intégrité des données déposées par le Client sur le Logiciel via le Site internet. Le Client reconnaît que le Logiciel étant exploité sur une plateforme mutualisée. A ce titre { society.name } fera ses meilleurs efforts afin de maintenir l’exploitation du serveur à un niveau permettant une utilisation normale du Logiciel par le Client. Toutefois, en cas de surcharge de la plateforme rendant impossible l’utilisation du Logiciel, la responsabilité de { society.name } ne pourrait être engagée.</p>
                    <h5 className="card-title text-oth-black text-500">3.2 – Obligations du Client</h5>
                    <p className="card-title">Le Logiciel n’étant accessible que via le Site internet, { society.name } remettra un mot de passe et un identifiant (ci-après les « Identifiants ») au Client. Ces Identifiants sont remis après acceptation du Contrat par le Client. Le Client est seul responsable de toutes conséquences découlant notamment de l’usage qu’il fait des Identifiants, de la perte de ces derniers ou de leur usage par un tiers. La responsabilité de { society.name } ne pourra être engagée de ce fait. Le Client reste seul responsable du choix du terminal se connectant au Logiciel et au Site Internet et reconnaît avoir pris connaissance des spécificités techniques du terminal requises pour faire fonctionner le Logiciel. En cas de dysfonctionnement du terminal, notamment en cas de dysfonctionnement lié directement ou indirectement à l’utilisation du Logiciel, { society.name } ne pourra voir sa responsabilité engagée. Le Client est tenu, de payer les échéances mensuelles dues au titre de l’utilisation du Logiciel.</p>

                    <h4 className="card-title text-oth-black text-900">ARTICLE 4 – PROPRIETE INTELLECTUELLE</h4>
                    <p className="card-title">{ society.name } reconnaît détenir ou avoir obtenu tous les droits nécessaires liés à l’exploitation et à la commercialisation du Logiciel et du Site internet. Tous les éléments composant le Logiciel et le Site internet y compris (non limitativement) les codes sources, codes objets et leur documentation associée, restent la propriété de { society.name }. Le Client ne dispose que d’un droit d’utilisation du Logiciel, uniquement sur le Site internet et uniquement pour la durée du Contrat. Le droit d’utilisation ici concédé est non cessible et non transmissible. Le Client n’acquiert aucun droit sur les matériels, logiciels, données ou documents détenus par { society.name }. { society.name } assurera la défense du Client contre toute action de la part d’un tiers revendiquant un droit de propriété intellectuelle, ou se fondant sur une demande en concurrence déloyale ou en parasitisme, concernant le Logiciel (hors logiciels libres) et utilisé par le Client. Les obligations de { society.name } sont soumises à l’ensemble des conditions suivantes :</p>
                    <ul>
                        <li>le Client avisera { society.name } immédiatement et par écrit de l’existence de l’allégation ou réclamation, et communiquera à { society.name } tous les renseignements à sa disposition relatifs à cette allégation ou réclamation,</li>
                        <li>{ society.name } aura seul la direction de la défense et de toute négociation en vue d’une transaction,</li>
                        <li>pour autant, le Client coopèrera totalement avec { society.name } en tout ce qui concerne le règlement de l’allégation ou réclamation,</li>
                    </ul>
                    <p className="card-title">Si l’allégation ou réclamation en contrefaçon ou concurrence déloyale ou parasitisme a été reconnue fondée par les tribunaux ou si { society.name } estime, au vu des éléments objectifs dont il dispose, qu’elle est susceptible de l’être, le Client s’engage à accepter que { society.name }, à son choix :</p>
                    <ul>
                        <li>obtienne pour le Client le droit de continuer à utiliser le Logiciel,</li>
                        <li>ou remplace le Logiciel par un logiciel ou progiciel équivalent non contrefaisant,</li>
                        <li>ou modifie le Logiciel de façon à ce qu’il cesse d’être contrefaisant.</li>
                    </ul>
                    <p className="card-title">Toutefois les dispositions ci-dessus ne s’appliquent pas lorsque l’allégation ou réclamation est relative à une utilisation du Logiciel par le Client non conforme aux conditions du Contrat.</p>

                    <h4 className="card-title text-oth-black text-900">ARTICLE 5 – CONDITIONS FINANCIÈRES</h4>
                    <p className="card-title"><b>Les logiciels développés sur mesure et installés sur le serveur interne du Client : </b>les coûts sont évalués en fonction du temps mis sur le développement et la mise en place du projet et sont  indiqués sur le devis. Les ajouts de nouvelles fonctionnalités demandés ultérieurement feront objet d’un nouveau devis.</p>
                    <p className="card-title"><b>Les logiciels en ligne (SaaS) : </b>compte tenu de l’entretien régulier et des mises à jour effectués sur ces logiciels, ils donnent au Client la possibilité de choisir entre des formules d’abonnement à des coûts fixes indiqués sur la page internet dédiée au Logiciel retrouvable sur le site web de { society.name }. Ces prix ne tiennent pas compte des coûts générés chez le Client par l’usage de sa connexion internet.</p>
                    <p className="card-title">Les frais de création de l’espace du Client en ligne à la signature du contrat sont fixés à { accountCeiling.registrationPrice } Hors Tax (HT) et son payable une seule fois pour toute la durée du contrat.</p>
                    <p className="card-title">Nos formations pour l’utilisation du Logiciel sont assurées à titre gracieux à tous nos clients.</p>

                    <h4 className="card-title text-oth-black text-900">ARTICLE 6 - RESPONSABILITÉ</h4>
                    <h5 className="card-title text-oth-black text-500">6.1 - Conditions Générales</h5>
                    <p className="card-title">{ society.name } ne pourra en aucun cas être tenu responsable d’éventuels dommages causés au Client ou au tiers résultant :</p>
                    <ul>
                        <li>de tous cas de force majeure, fait du Client ou d’un tiers ;</li>
                        <li>de perte, d’altération, de divulgation de données du fait du Client ou d’un tiers ;</li>
                        <li>de l’intrusion de programme malveillant (non limitativement : virus, malware) ou de spam sur le terminal du Client ou son logiciel de messagerie ;</li>
                        <li>d’interruption de l’accès au Logiciel en cas de maintenance du Logiciel et/ou du Site Internet par { society.name } ;</li>
                        <li>de la fiabilité des transmissions des données, des temps d’accès, des éventuelles restrictions d’accès sur le réseau Internet ;</li>
                        <li>des temps de latence dû au réseau Internet ainsi que de l’utilisation d’Internet faite par le Client ;</li>
                        <li>des coûts de connexion et/ou dépassement de forfait de connexion internet subit par le Client du fait de l’utilisation du Logiciel ou du Site Internet.</li>
                    </ul>
                    <p className="card-title">La responsabilité de { society.name } ne pourra être engagée qu’à la condition que le Client rapporte la preuve d’une faute de { society.name }. En pareille hypothèse, le dédommagement du Client ne saurait excéder la moitié des sommes versées par le Client à { society.name } au titre de l’utilisation du Logiciel.</p>
                    <h5 className="card-title text-oth-black text-500">6.2 - Protection des données personnelles</h5>
                    <p className="card-title">
                        Confère « Protection des données » menu accessible dans le panel utilisateur.
                    </p>
                    
                    <h4 className="card-title text-oth-black text-900">ARTICLE 7 - RÉSILIATION</h4>
                    <h5 className="card-title text-oth-black text-500">7.1 - Résiliation du fait de { society.name }</h5>
                    <p className="card-title">{ society.name } peut suspendre l’accès au Logiciel ou résilier le Contrat à tout moment sans qu’aucune indemnisation ne soit exigible par le Client, que ce dernier bénéficie de l’Option Premium, Gold ou non. En pareille hypothèse, { society.name } est tenu de rendre les données relevant de la propriété du Client et qui ont été remises par le Client via le Logiciel. La résiliation du contrat est effective après réception par mail du Client de la notification de résiliation envoyée par { society.name }. </p>
                    <h5 className="card-title text-oth-black text-500">7.2 - Résiliation du fait du Client</h5>
                    <p className="card-title">Le Client peut résilier le Contrat à tout moment, par simple notification par mail (aucun remboursement ne sera effectué).</p>
                    <p className="card-title">Toutes résiliation anticipée du Client donnera lieu au paiement de plein droit par le Client à { society.name } de la totalité des mensualités consommées. Toutefois, le Client peut résilier le Contrat pour manquement de { society.name } en envoyant à { society.name } par lettre recommandée un courrier mentionnant le manquement de { society.name } et demandant la résiliation du Contrat en respectant un préavis d’un mois.</p>
                    <h5 className="card-title text-oth-black text-500">7.3 - Réversibilité</h5>
                    <p className="card-title">La résiliation a pour effet : </p>
                    <ul>
                        <li>L’invalidation des Identifiants du Client sur le Site internet de { society.name }, rendant au Client l’accès impossible au Logiciel</li>
                        <li>La mise à disposition par { society.name } en téléchargement de toutes données propriétés du Client et déposées via le Logiciel</li>
                    </ul>

                    <h4 className="card-title text-oth-black text-900">ARTICLE 8 - CONFIDENTIALITE</h4>
                    <p className="card-title">Les Parties s’engagent au plus strict respect du secret des affaires en ce qui concerne toute information transmise par l’autre Partie et dont elles ont pris connaissance, à l’occasion de l’exécution du Contrat. Les documents ou renseignements confiés par le Client, ainsi que les dossiers élaborés par { society.name }, à l'aide de ces documents et renseignements, sont couverts par le secret des affaires. Toutefois, ne seront pas considérés comme confidentiels les informations, documents ou outils qui :</p>
                    <ul>
                        <li>étaient du domaine public au moment de leur divulgation ou sont tombés dans le domaine public sans qu'il y ait eu contravention aux présentes dispositions</li>
                        <li>résultent de connaissances internes à l'une ou l'autre des Parties sans qu'il y ait eu violation par elle de la présente obligation de confidentialité</li>
                        <li>ont été reçus de manière licite, par l'une ou l'autre des Parties, de tiers, sans obligation de confidentialité.</li>
                    </ul>

                    <h4 className="card-title text-oth-black text-900">ARTICLE 9 - DISPOSITIONS GENERALES</h4>
                    <p className="card-title">Chacune des Parties conservera à sa charge l'ensemble des frais et autres débours engagés par elle au titre des présentes, et au titre des opérations qui y sont stipulées. Les présentes ne pourront être modifiées par un avenant écrit et signé entre les Parties aux présentes. Les termes des présentes prévaudront sur toute stipulation contraire que comporterait tout autre document technique ou commercial, échangé entre les Parties, sauf accord exprès contraire des Parties. Le fait pour une Partie de tolérer un manquement quelconque de l'autre Partie dans l'exécution de ses obligations aux termes des présentes ne devra en aucun cas être interprété comme une renonciation tacite au bénéfice de ses autres obligations.</p>

                    <h4 className="card-title text-oth-black text-900">ARTICLE 10 - DROIT APPLICABLE ET TRIBUNAL COMPETENT</h4>
                    <p className="card-title">{ society?.dataProtection?.applicableLawText1?.fr }</p>
                    <h6>{ society?.dataProtection?.applicableLawText2?.fr }</h6>
                </ModalBody>
            </Modal>
        </>
    )
}